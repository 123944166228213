import styled from "styled-components"

const AboutMAPStyles = styled.div`
  .aboutMAP {
    &__content {
      color: ${({ theme }) => theme.text.text_19};
    }
    &__title {
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
    }
    &__description {
      font-size: 18px;
      line-height: 24px;
    }
    &__small-des {
      p {
        margin-bottom: 5px !important;
      }
    }
  }

  .logo-item {
    object-fit: contain;
    width: 100%;
    height: 80px;
    /* background: #E4E4E4; */
    /* border-radius: 20px; */
  }
`

export default AboutMAPStyles
