import React from "react"
import PropTypes from "prop-types"
import Row from "antd/lib/row"
import Col from "antd/lib/col"
import Tooltip from "antd/lib/tooltip"

const LogoList = ({ data }) => {
  return (
    <Row gutter={[20, 20]}>
      {data?.map((item, idx) => (
        <Col md={8} xs={12}>
          <Tooltip key={String(idx)} title={item.name}>
            <a href={item.url} target="_blank" rel="noreferrer">
              <img alt={item.name} className="logo-item" src={item.image} />
            </a>
          </Tooltip>
        </Col>
      ))}
    </Row>
  )
}

LogoList.propTypes = {
  data: PropTypes.array,
}

export default LogoList
