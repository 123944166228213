import React from "react"

import PublicLayout from "components/layouts/PublicLayout"
import Seo from "components/seo"
import AboutMAP from "containers/AboutMAP"

const AboutMAPPage = () => (
  <PublicLayout>
    <Seo title="About MAP" />

    <AboutMAP />
  </PublicLayout>
)

export default AboutMAPPage
