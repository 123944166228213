export const aboutMAPData = {
  displayTitle: {
    vi: "Về Dự án",
    en: "About MAP",
  },
  content: `<p>Tháng thực hành Nghệ thuật (MAP) là một dự án trao đổi nghệ thuật quốc tế hang năm do Heritage Space khởi xướng và vận hành từ 2015. Mỗi năm, MAP đặt ra một chủ đề làm việc chuyên biệt, mời sự tham gia của các nghệ sĩ, giám tuyển quốc tế sang Hà Nội thực hành và trao đổi với các nghệ sĩ trẻ tài năng Việt Nam. </p>
  <p>MAP 2021 có chủ đề “Khoảng cách Trắng” – phản ứng với khoảng cách được tạo ra bởi cơn đại dịch, và tư duy lại những vùng trống sẵn có trong đời sống thực thể và tâm lí của con người. 26 nghệ sĩ và chuyên gia đến từ Đức, Mĩ, Nhật Bản, Hàn Quốc, Ấn Độ, Thụy Sĩ, Phần Lan, Pháp và Việt Nam tham dự trong dự án lần này. MAP 2021 bao gồm giai đoạn trao đổi và lưu trú trực tuyến kéo dài 5 tháng, sau đó là một triển lãm hỗn hợp tại Hà Nội trong tháng 11/2021 và tại the-white-distance.art tới hết tháng 10/2022.</p>
  <p>Xem thêm về dự án tại đây: <a target="_blank" style="text-decoration: underline" href="http://bit.ly/monthofartpractice">http://bit.ly/monthofartpractice</a></p>
  <p>Xem thêm về chủ đề: <a target="_blank" style="text-decoration: underline" href="https://docs.google.com/document/d/1qXLaTNBzM-XlZHPgq-8S8_xP538CuVOe/edit?usp=sharing&ouid=104400114750659981888&rtpof=true&sd=true">Khoảng cách trắng tại đây.</a></p>
  <p>Month of Arts Practice, abbreviated as MAP, is an annual art project of Heritage Space. MAP was initiated and operated since 2015 with a view to creating a space for composing and developing contemporary art on the basis of cooperation and interaction among Vietnamese and international artists. Each year, MAP sets out a specific theme, inviting the participation of international acclaimed artists and curators come to Hanoi (Vietnam) to practice and exchange with talented young Vietnamese artists.</p>
  <p>MAP 2021 has the theme "the White distance" - a response to the distances created by the pandemic, and also wants to rethink the existing gaps in the physical and psychological human life. There are 26 artists and experts from Germany, USA, Japan, Korea, India, Switzerland, Finland, France and Vietnam participated in this project. MAP 2021 includes a 5-month virtual residency and exchange period, followed by a hybrid exhibition in Hanoi and online launched from Nov 2021.</p>
  <p>For more information about the project, please see here: <a target="_blank" style="text-decoration: underline" href="http://bit.ly/monthofartpractice">http://bit.ly/monthofartpractice</a></p>
  <p>Reading about: <a target="_blank" style="text-decoration: underline" href="https://docs.google.com/document/d/1XKqTNCz5IIcY7esp4ASkPxmiDsm3O6hS/edit?usp=sharing&ouid=104400114750659981888&rtpof=true&sd=true">the WHITE DISTANCE concept note here.</a></p>
  `,
}

export const SPONSORS = [
  {
    name: "Goethe-Institut Hanoi",
    url: "https://www.goethe.de/ins/vn/en/sta/han.html",
    image: "/images/logo_sponsor/logo_goethe.jpg",
  },
  {
    name: "Korea Foundation",
    url: "https://www.kf.or.kr/kfEng/main.do",
    image: "/images/logo_sponsor/logo_KF.jpg",
  },
  {
    name: "Japan Foundation Center for Cultural Exchange in Vietnam",
    url: "https://jpf.org.vn",
    image: "/images/logo_sponsor/logo_JF.jpg",
  },
  {
    name: "Swiss Embassy in Vietnam",
    url: "https://www.eda.admin.ch/countries/vietnam/en/home/representations/embassy.html",
    image: "/images/logo_sponsor/logo_embassy.jpg",
  },
];

export const PARTNERS = [
  {
    name: "Complex 01",
    url: "https://www.facebook.com/complex01.vn",
    image: "/images/logo_partner/logo_complex.jpeg",
  },
  {
    name: "Do A Front",
    url: "https://www.doafront.org/",
    image: "/images/logo_partner/logo_doa_front.svg",
  },
  {
    name: "Devtify Technologies Corporation",
    url: "https://devtify.com",
    image: "/images/logo_partner/logo_devtify.png",
  },
  {
    name: "LUXUO / Art Republik",
    url: "https://luxuo.vn/category/culture",
    image: "/images/logo_partner/logo_art_republik.png",
  },
  {
    name: "Hanoi Grapevine",
    url: "https://hanoigrapevine.com",
    image: "/images/logo_partner/logo_HG.jpg",
  },
  {
    name: "Artcific",
    url: "https://artcific.com",
    image: "/images/logo_partner/logo-artcific.svg",
  },
]
