import React from "react"
// import PropTypes from "prop-types"
import { Col, Row } from "antd"

import PageTitle from "components/common/PageTitle"
import PageContent from "components/common/PageContent"

import { aboutMAPData, PARTNERS, SPONSORS } from "data/aboutMap"
import AboutMAPStyles from "./styles"
import { CREDITS_INFO } from "data/credits"
import { CONTACTS_INFO } from "data/contacts"
import LogoList from "./LogoList"

const AboutMAP = () => {
  return (
    <AboutMAPStyles className="container-landing container-wrapper">
      <PageTitle title="About MAP" />

      <PageContent className="aboutMAP__content">
        <div className="mb-80">
          <Row gutter={[20, 20]}>
            <Col xl={12} lg={12} md={24} sm={24}>
              <div className="aboutMAP__title">
                <div className="mb-10">{aboutMAPData.displayTitle.vi}</div>
                <div>{aboutMAPData.displayTitle.en}</div>
              </div>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24}>
              <div
                className="aboutMAP__description"
                dangerouslySetInnerHTML={{ __html: aboutMAPData.content }}
              />
            </Col>
          </Row>
        </div>

        <Row gutter={[20, 40]}>
          <Col xl={12} lg={12} md={24} sm={24}>
            <div className="aboutMAP__title">Our partners</div>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24}>
            <LogoList data={PARTNERS} />
          </Col>

          <Col xl={12} lg={12} md={24} sm={24}>
            <div className="aboutMAP__title">Our sponsors</div>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24}>
            <LogoList data={SPONSORS} />
          </Col>

          <Col xl={12} lg={12} md={24} sm={24}>
            <div className="aboutMAP__title">Credits</div>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24}>
            <div
              className="aboutMAP__description aboutMAP__small-des"
              dangerouslySetInnerHTML={{ __html: CREDITS_INFO }}
            />
          </Col>

          <Col xl={12} lg={12} md={24} sm={24}>
            <div className="aboutMAP__title" id="contactsMap">
              Contacts
            </div>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24}>
            <div
              className="aboutMAP__description aboutMAP__small-des"
              dangerouslySetInnerHTML={{ __html: CONTACTS_INFO }}
            />
          </Col>
        </Row>
      </PageContent>
    </AboutMAPStyles>
  )
}

AboutMAP.propTypes = {}

export default AboutMAP
